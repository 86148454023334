import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import Sorters from './components/Sorters';
import Results from './components/Results';
import RestrictedResult from './components/RestrictedResult';

import { emitEvent, eventTypes } from '../../../events';
import { getRandomThumbs } from '../../../utils';
import settings from '../../../settings';
import { debounce } from '../../../utils';

const styles = (theme) => ({
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    display: 'block',
    margin: '8rem auto',
  },
  optionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filtersWrapper: {
    padding: '0.5rem 0',
  },
  sortersWrapper: {
    padding: '0.5rem 0',
  },
  resultsContainer: {
    padding: '0rem 1rem',
  },
  moreResultsContainer: {
    paddingBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
  },
  restrictedContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  restrictedUnlock: {
    position: 'absolute',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 999,
    textAlign: 'center',
    top: theme.spacing(7),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
});

class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: null,
      nextResultsId: null,

      sortBy: 'popular',

      loading: false,
      loadingMore: false,
      numRestricted: 0,
    };
    this.debouncedApiQuery = debounce(this.apiQuery, settings.values.videoSearchFetchDelay);
  }

  componentDidMount() {
    this.fetchResults(false);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.fen !== prevProps.fen) {
      this.fetchResults(false);
    }
  }

  handleSortChange = (e) => {
    this.setState({
      results: null,
      sortBy: e.target.value,
    }, () => {
      this.fetchResults();
    });
  }

  apiQuery = (queryMore) => {
    const { fen, user } = this.props;
    const { sortBy } = this.state;

    const position = fen.split(' ')[0];
    let url = `${settings.videoApiUrl}/results?position=${position}&ordering=${sortBy}`;

    if (queryMore) {
      url += `&next_results_id=${this.state.nextResultsId}`;
    }
    return user.getIdToken().then(authToken => {
      const headers = new Headers({
        'accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      });
      fetch(url, {
        headers
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Server error: ${response.status} ${response.statusText}`);
      })
      .then(data => {
        const {
          results: newResults, next_results_id: nextResultsId,
          num_restricted: numRestricted,
        } = data;
        this.setState({
          results: queryMore ? [...this.state.results, ...newResults] : newResults,
          loading: false,
          loadingMore: false,
          nextResultsId,
          numRestricted,
        });
      })
      .catch(err => {
        this.setState({
          results: null,
          loading: false,
          loadingMore: false,
          nextResultsId: null,
        }, () => {
          alert(err);
        });
      })
    })
  }

  fetchResults = (queryMore = false) => {
    const { nextResultsId } = this.state;
    this.setState({
      loading: !queryMore,
      loadingMore: queryMore,
      nextResultsId: queryMore ? nextResultsId : null,
    }, () => {
      this.debouncedApiQuery(queryMore);
    });
  }

  render() {
    const { classes } = this.props;
    const { onUpgradeClick } = this.props;
    const { onUpgradeLoading } = this.props;

    const { sortBy } = this.state;
    const { results, nextResultsId } = this.state;
    const { loading, loadingMore } = this.state;
    const { numRestricted } = this.state;

    return (
      <div className={classes.container}>
        { loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loading} />
          </div>
        )}
        { results !== null && (
          <div className={classes.resultsContainer}>
            { results.length > 0 && (
              <div className={classes.optionsWrapper}>
                <div className={classes.sortersWrapper}>
                  <Sorters
                    sortBy={sortBy}
                    onChange={this.handleSortChange}
                  />
                </div>
                <div className={classes.filtersWrapper}>
                  <Link to={settings.routes.SETTINGS_VIDEOS} target="_blank" rel="noopener noreferrer">
                    <Button
                      component="span"
                      size="small"
                      endIcon={<SettingsIcon />}
                      style={{ textTransform: 'none' }}
                    >
                      Configure
                    </Button>
                  </Link>
                </div>
              </div>
            )}
            <Results results={results} />
            { numRestricted === 0
            ? (
              <>
              { nextResultsId && loadingMore && (
                <div className={classes.moreResultsContainer}>
                  <CircularProgress className={classes.loading} />
                </div>
              )}
              { nextResultsId && !loadingMore && (
                <div className={classes.moreResultsContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { this.fetchResults(true)}}>More Results</Button>
                </div>
              )}
              </>
            )
            : (
              <div className={classes.restrictedContainer}>
                <div className={classes.restrictedUnlock}>
                  <Typography variant="h6">More results available</Typography>
                  <Typography>Upgrade your account to get unlimited access</Typography>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    disabled={onUpgradeLoading}
                    onClick={() => {
                      emitEvent(eventTypes.subscriptionPlansDialogOpenExplorer);
                      onUpgradeClick();
                    }}
                    style={{ marginTop: '1rem' }}
                  >Upgrade</Button>
                </div>
                <div className={classes.restrictedResults}>
                  { getRandomThumbs(numRestricted).map((thumb, i) => <RestrictedResult key={`RestrictedResult-${i}`} thumb={thumb} /> )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Videos);
