import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DialogContentText from '@material-ui/core/DialogContentText';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SubscriptionStorage, UserVideoSetting } from '../../db';

import { getSelectTheme } from '../../utils';

import settings from '../../settings';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0)
  },
  select: {
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
});

class VideosTab extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      excludedChannels: undefined,
      subscription: undefined,
    }
    this.userVideoSettings = new UserVideoSetting(user.uid);
  }

  async componentDidMount() {
    const { getSubscription } = this.props;
    const sub = await getSubscription();
    this.setState({
      subscription: sub !== null ? sub : null,
    }, async () => {
      await this.fetchExcludedChannels();
    })
  }

  async fetchExcludedChannels() {
    const excludedChannelIds = await this.userVideoSettings.getExcludedChannels()
    this.setState({
      excludedChannels: excludedChannelIds
      .map(id => settings.observedChannels.find(e => e.value === id)),
    })
  }

  async handleExcludedChannelsChange(updatedExcludedChannels) {
    const { subscription } = this.state;
    const { onUpgradeClick } = this.props;
    if (subscription === null || subscription.ended_at) {
      onUpgradeClick();
      return;
    }
    if (updatedExcludedChannels.length > 10) {
      alert('You can set at most 10 excluded channels');
      return;
    }
    this.setState({
      excludedChannels: updatedExcludedChannels,
    }, async () => {
      await this.userVideoSettings.updateExcludedChannels(
        updatedExcludedChannels.map(e => e.value));
    });
  }

  render() {
    // Styling
    const { theme, classes } = this.props;

    const { subscription } = this.state;
    const { excludedChannels } = this.state;

    const selectTheme = getSelectTheme(theme);

    return (
      <React.Fragment>
        <DialogContentText classes={{ root: classes.root }}>
          Customize your video search result settings
        </DialogContentText>

        <div className={classes.excludedChannelsWrapper}>
          <p>
            Exclude videos from channels you don't want to see:
          </p>
          { subscription === undefined || excludedChannels === undefined
          ? <CircularProgress size={20} />
          : (
            <Select
              className={classes.select}
              isMulti
              isClearable={true}
              isSearchable={true}
              placeholder="Excluded channels"
              options={settings.observedChannels}
              value={excludedChannels}
              onChange={async o => await this.handleExcludedChannelsChange(o)}
              theme={selectTheme}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

VideosTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(VideosTab);
