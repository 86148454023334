import * as Chess from 'chess.js';

const STARTING_POSITION = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR';

export const moveUciToObj = moveUci => ({
  from: moveUci.substr(0, 2),
  to: moveUci.substr(2, 2),
  promotion: moveUci.substr(4, 1),
});

export const prepareForest = (forest) => {
  let curId = 0;
  const hangingBranches = [];
  const dfs = (v, move) => {
    v.id = curId;
    curId++;
    // TODO: set real score here, for now it's just a dummy placeholder
    v.score = {
      score: 0,
      mate: null,
    };
    v.children = v.children.filter(e => e !== null);
    for (let i = 0; i < v.children.length; ++i) {
      const move_uci = v.children[i].move;
      const board = new Chess(v.fen);
      const board_move = board.move(moveUciToObj(move_uci));
      if (board_move !== null) {
        v.children[i] = dfs(v.children[i], { uci: move_uci, san: board_move.san });
      } else {
        // if for some reason the move cannot be made on the board, e.g. if the position is invalid, chess.js cannot make moves
        // for instance, where there are less than 2 kings on the board
        // then we "detach" this node and prepare it as a new tree
        hangingBranches.push(v.children[i]);
        v.children[i] = null;
      }
    }
    v.children = v.children.filter(e => e !== null);
    delete v.move;
    if (move === null) {
      return v;
    }
    return {
      move,
      child: v,
    }
  }

  const res = [];
  for (const root of forest) {
    const prepared = dfs(root, null, false);
    res.push(prepared);
  }
  while (hangingBranches.length > 0) {
    const root = hangingBranches.pop();
    const prepared = dfs(root, null, false);
    res.push(prepared)
  }
  return res;
}

export const computeMainlines = (forest, minHeight = 0) => {
  const dfs = v => {
    if (v.children.length === 0) {
      v.height = 0;
    } else {
      for (let i = 0; i < v.children.length; ++i) {
        v.children[i].child.mainline = false;
        dfs(v.children[i].child);
      }
      v.height = Math.max(...v.children.map(u => u.child.height)) + 1;
      for (let i = 0; i < v.children.length; ++i) {
        if (v.children[i].child.height+1 === v.height) {
          v.children[i].child.mainline = true;
          break;
        }
      }
    }
  }
  const res = [];
  for (const root of forest) {
    dfs(root);
    root.mainline = true;
    if (root.height >= minHeight) {
      res.push(root);
    }
  }
  return res;
}

export const expandForest = (forest) => {
  const res = [];
  for (const root of forest) {
    if (root.fen.split(' ')[0] === STARTING_POSITION && root.children.length > 0) {
      const sidelines = root.children.slice(1);
      root.children = [root.children[0]];
      res.push(root);
      for (const u of sidelines) {
        const v = {
          fen: root.fen,
          id: root.id,
          orientation: root.orientation,
          score: root.score,
          timestamps: root.timestamps,
          children: [u],
        }
        res.push(v);
      }
    } else {
      res.push(root);
    }
  }
  return res;
}

export const urlToVideoId = url => {
  const regexes = [
    /watch\?v=([a-zA-Z\-0-9_]+)/,  // full link
    /youtu.be\/([a-zA-Z\-0-9_]+)/,  // shorten link
  ];
  for (const regex of regexes) {
    const m = url.match(regex);
    if (m) {
      return m[1];
    }
  }
  return null;
}
