
/* eslint-disable no-unused-vars */

import React from 'react';

import deepOrange from '@material-ui/core/colors/deepOrange';
import gray from '@material-ui/core/colors/grey';

/* eslint-enable no-unused-vars */

const Quote = (text, author) => ({ text, author });

const maxMobileWidth = 767;

const settings = {
  title: 'Chessvision.ai',

  hostUrl: process.env.REACT_APP_HOST_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  videoApiUrl: process.env.REACT_APP_VIDEO_API_URL,

  chromeExtensionIDs: [
    'johejpedmdkeiffkdaodgoipdjodhlld',  // production
    'emnghaohbckmigclaigmhnfmmfinadcg',  // dev
    'ahlhhabiaeejjmkilgleaillpdgjaoib',  // local build crx file
    'dcgcjmlichefeccicongclebmlcngjmc',  // local non-crx file
    'ajnejkcholnlbchoegoapkafkpahfkbb',  // another local non-crx file
    'okigeaeogfnlhlpcamhdpdmofkgjhlcj',  // yet another local non-crx file
    'hclgiobjbkjhgpokmpbigkknmbcldikp',  // local non-crx file, for lichess video search version
    'ajmciefemddgobjhkhfllnfgajhfejfj',  // local build crx file for 3.2.0
    'nipdgalabpjnfpfjahgigephblmdoehf',  // yet another local 3.2.0
    'lmdmmcobbcjfamfjhahpfaoeceimcgfh',  // yet more another local 3.2.0
    'fdjkmkchhmofdbggkklaikegpighecno',  // local for 3.2.1
    'ggndblkeeknjcpadigibmhgaadkcippc',  // local for 3.3.1
    'oefcnfjdpnbapdanaelcdbemcabhcnem',  // local for 3.4.1
    'ggifimnbmcagalhijckccjpnpbmfhmcm',  // yet another local for 3.4.1
    'jkonnhalgocffiopebekhcggbnoambad',  // another local for 3.4.1
  ],

  useAnalytics: process.env.REACT_APP_USE_ANALYTICS === "true",
  earlyAccess: false,

  surveyUrl: 'https://forms.gle/Kf4GrbYkQu6et3eW8',

  defaultTheme: 'dark',  // either 'light' or 'dark'

  observedChannels: [
    {
        label: "agadmator's Chess Channel",
        value: "UCL5YbN5WLFD8dLIegT5QAbA"
    },
    /*
    {
        label: "Ajedrez Guerrero",
        value: "UCPzGFCKM4ow6na5JbFb3YKg"
    },
    */
    {
        label: "Astaneh Chess",
        value: "UCNB4vCw1iI8c-vMkgPZIz5w"
    },
    {
        label: "Charlotte Chess Center & Scholastic Academy",
        value: "UCGApU6Z3AVmpETGmgfzvvfQ"
    },
    {
        label: "Chess Club and Scholastic Center of Atlanta",
        value: "UCqLLqbclDQ6IQg39Wsgy-4w"
    },
    {
        label: "Chess Talk",
        value: "UC6HDXr-sNPnWLF_Q-y3KduA"
    },
    {
        label: "Chess Vibes",
        value: "UChDxbOUQRXEZ1zdI14Zyx9w"
    },
    {
        label: "Chess.com",
        value: "UC5kS0l76kC0xOzMPtOmSFGw"
    },
    {
        label: "ChessBase India",
        value: "UCIsEhwBMPkRHsEgqYAPQHsA"
    },
    /*
    {
        label: "Chessboxing",
        value: "UCpwvzI-DYxfR3i75k3tgINQ"
    },
    */
    {
        label: "ChessCoach Andras",
        value: "UCcYZTGsTO5TbCaA1O0wcBzw"
    },
    {
        label: "ChessDojo",
        value: "UC4liTXRJ-XknH6OtKz-tOuw"
    },
    {
        label: "Chessfactor",
        value: "UCgRUc4wyyMCnULY_XWPYXmA"
    },
    {
        label: "ChessGoals",
        value: "UCKdqbOcQab3knJ2-Jb7cqQA"
    },
    {
        label: "ChessNetwork",
        value: "UCCDOQrpqLqKVcTCKzqarxLg"
    },
    {
        label: "ChessPage1",
        value: "UCO2QPmnJFjdvJ6ch-pe27dQ"
    },
    {
        label: "Daniel Naroditsky",
        value: "UCHP9CdeguNUI-_nBv_UXBhw"
    },
    {
      label: "Endgame Strategies",
      value: "UCaB0DemNX-LHv6kEPJJAuOQ"
    },
    {
        label: "Eric Rosen",
        value: "UCXy10-NEFGxQ3b4NVrzHw1Q"
    },
    {
        label: "FM William Graif",
        value: "UCVsZMQjkmPHR8x39eW-NOMA"
    },
    {
        label: "GingerGM",
        value: "UClV9nqHHcsrm2krkFDPPr-g"
    },
    {
        label: "GMBenjaminFinegold",
        value: "UC6EnFbK-P5q0zeaqI5yobKg"
    },
    {
        label: "GMHikaru",
        value: "UCweCc7bSMX5J4jEH7HFImng"
    },
    {
        label: "GM Talks",
        value: "UCxQnqfegJMhTznPAeHa0Tiw"
    },
    {
        label: "GothamChess",
        value: "UCQHX6ViZmPsWiYSFAyS0a3Q"
    },
    {
        label: "Han Schut",
        value: "UCDF6WO2qPKXzP6kVvlfhk_g"
    },
    {
        label: "Hanging Pawns",
        value: "UCkJdvwRC-oGPhRHW_XPNokg"
    },
    {
        label: "IM Alex Banzea",
        value: "UC76v5qR-TKSRalQeKskzoDg",
    },
    {
        label: "IM Giorgi Sibashvili",
        value: "UC9Qyitf-HVpVVNhisRpMKhQ"
    },
    {
        label: "John Bartholomew",
        value: "UC6hOVYvNn79Sl1Fc1vx2mYA"
    },
    {
        label: "Jonathan Schrantz",
        value: "UCIh4QOvH7v8eQNdUb2As9VA"
    },
    {
        label: "kingscrusher",
        value: "UCDUDDmslypVXYoUsZafHSUQ"
    },
    {
        label: "LightSquares",
        value: "UC8oyKyv_-E1C1xKcit2CR5w"
    },
    /*
    {
        label: "MESTRE GABRIELMIGUEL",
        value: "UC7aAoB1gvz84hj3SG6QbOpw"
    },
    */
    {
        label: "Miodrag Perunovic",
        value: "UChxLXqMph7cRG_MHIIhlZ7A"
    },
    {
        label: "Molton",
        value: "UC2OVvr0vXGoM_yiEedZT5fA",
    },
    {
        label: "NM Robert Ramirez",
        value: "UCzalw8TnI9ynLIfOOcyVrsA"
    },
    {
        label: "PowerPlayChess",
        value: "UCMBATpFb--uLNAODOVWvCTA"
    },
    {
      label: "Remote Chess Academy",
      value: "UCsKZ2yOsgfNxln8xH5WkGvg"
    },
    {
      label: "Robert Ris",
      value: "UCWZ3Tuk0HpuUyESyXLxP8Tw"
    },
    {
        label: "Saint Louis Chess Club",
        value: "UCM-ONC2bCHytG2mYtKDmIeA"
    },
    /*
    {
        label: "The Chess Giant",
        value: "UC9kP6NUvOS4_E7az0nfl7TQ"
    },
    */
    {
        label: "thechesswebsite",
        value: "UCHz5JQAUSkjxrosDIWCtEdw"
    },
    /*
    {
        label: "Tomek Jaskółka",
        value: "UCKdmFeqK0KF4oEJxNWY7luw"
    },
    {
        label: "Učite šah s velemajstorom Jankovićem",
        value: "UCYvq-ghyAhdu9sj0gGkETXA"
    },
    {
        label: "vampirechicken",
        value: "UCGYOTPRRtTJ5wnQbffC7YhQ"
    }
    */
  ],

  freeWatchVideos: [
    {
      id: '0EmnA84OwwY',
      title: "Paul Morphy's 5 Most Brilliant Chess Moves",
      author: "GingerGM",
    },
    {
      id: 'RH2dwz8Ppjw',
      title: 'Two Queen Sacrifices are Better Than One!',
      author: 'agadmator',
    },
    {
      id: 'XaQ7e6W4Qq8',
      title: '7 Passed Pawns - Duda vs Firouzja',
      author: 'ChessNetwork',
    },
    {
      id: 'd5n_RuvnmCo',
      title: 'Gotham Chess Guide Part 1: 1000+ | FREE PIECES GALORE!',
      author: 'GothamChess',
    },
    {
      id: '3DpZEHS6E84',
      title: 'Everyone Should Know this Ponziani Opening Trap',
      author: 'Eric Rosen'
    }
  ],

  subscriptionTiers: [
    {
      title: 'Monthly',
      subtitle: '',
      plan: 'monthly',
      // price: '7.99',
      price: '5.99',
      discountPrice: '4.99',
      unit: 'month',
      buttonText: 'Select',
      buttonColor: 'primary',
      buttonVariant: 'outlined',
    },
    {
      title: 'Yearly',
      subtitle: 'Most Popular',
      plan: 'yearly',
      // price: '59.99',
      price: '45.99',
      discountPrice: '34.99',
      unit: 'year',
      buttonText: 'Select',
      buttonColor: 'secondary',
      buttonVariant: 'contained',
    },
  ],

  links: {
    TOS: 'https://chessvision.ai/terms',
    PRIVACY_POLICY: 'https://chessvision.ai/web/privacy-policy.html',
    NOTIFICATION_SETTINGS: 'https://chessvision.ai/notifications',
  },

  routes: {
    HOME: '/',

    SIGN_IN: '/',
    SIGN_UP: '/sign-up',
    RESET_PASSWORD: '/reset-password',

    SETTINGS: '/settings',
    SETTINGS_VIDEOS: '/settings/videos',
    BOARD_EXPLORER: '/explorer',
    VIDEO_SEARCH: '/search',
    DIAGRAM_LIBRARY: '/library',
    VIDEO_WATCH: '/watch',

    CHECKOUT_SUBSCRIPTION_SUCCESSFUL: '/checkout/subscription/successful',
    CHECKOUT_SUBSCRIPTION_CANCELED: '/checkout/subscription/canceled',

    CHROME_EXTENSION_AUTH: '/chrome-extension/auth',
    CHROME_EXTENSION_UPGRADE: '/chrome-extension/upgrade',
    CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_SUCCESSFUL: '/chrome-extension/checkout/subscription/successful',
    CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_CANCELED: '/chrome-extension/checkout/subscription/canceled',
  },

  products: [
    { url: "https://ebook.chessvision.ai", title: "eBook Reader" },
    { url: "https://apps.apple.com/us/app/id1574933453", title: "iOS App" },
    { url: "https://play.google.com/store/apps/details?id=ai.chessvision.scanner", title: "Android App" },
    { url: "https://chrome.google.com/webstore/detail/chessvisionai-for-chrome/johejpedmdkeiffkdaodgoipdjodhlld", title: "Chrome Extension" },
    { url: "https://addons.mozilla.org/firefox/addon/chessvision-ai-for-firefox/", title: "Firefox Add-on" },
  ],

  credentials: {
    firebase: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
  },
  values: {
    analysisStatusIntervalPing: 1000 * 30,  // 30s
    supportEmail: 'hello@chessvision.ai',
    requireVerifiedEmail: false,
    videoSearchFetchDelay: 200, // in milliseconds
    maxMobileWidth,
    maxContainerWidth: 1024,
    book: {
      defaults: {
        type: 'lichess',
        speeds: ['blitz', 'rapid'],
        ratings: [1600, 1800, 2000, 2200],
      },
      choices: {
        type: ['lichess', 'master'],
        speeds: ['bullet', 'blitz', 'rapid', 'classical'],
        ratings: [1600, 1800, 2000, 2200, 2500],
      },
    },
  },

  messages: {
    tagline: "Watch chess videos like never before",
    checkout: {
      subscription: {
        successful: {
          title: "Subscription Successful",
          text: <span>Thank you!<br/> Now you are a <b>Member</b> and you can use the <b>full functionality</b> of the Web App, the Chrome Extension, and the Mobile Apps.<br/>Enjoy!</span>,
        },
        canceled: {
          title: "Checkout Canceled",
          text: <span>Your subscription was not purchased.<br/> Try again if you'd like to use the full functionality of the Web App, the Chrome Extension, and the Mobile Apps.</span>,
        },
      },
    },
    chromeExtensionCheckout: {
      subscription: {
        successful: {
          title: "Subscription Successful",
          text: <span>Thank you!<br/> Now you are a <b>Member</b> and you can use the <b>full functionality</b> of the Chrome Extension, the Mobile Apps, and this Web App.<br/>Enjoy!</span>,
        },
        canceled: {
          title: "Checkout Canceled",
          text: <span>Your subscription was not purchased.<br/> Try again if you'd like to use the full functionality of the Chrome Extension, the Mobile Apps, and this Web App.</span>,
        },
      },
    },
  },

  // courtesy of http://www.chess-poster.com/english/notes_and_facts/chess_quotes.htm
  // and other various sources
  quotes: [
    Quote("When you see a good move, look for a better one.", "Emanuel Lasker"),
    Quote("All I want to do, ever, is just play chess.", "Bobby Fischer"),
    Quote("I don’t believe in psychology. I believe in good moves.", "Bobby Fischer"),
    Quote("Chess is the art of analysis.", "Mikhail Botvinnik"),
    Quote("A bad day of chess is better than any good day at work.", "Anonymous"),
    Quote("The essence of chess is thinking about what chess is.", "David Bronstein"),
    Quote("Every chess master was once a beginner.", "Chernev"),
    Quote("The hardest game to win is a won game.", "Emanuel Lasker"),
    Quote("The most powerful weapon in chess is to have the next move.", "David Bronstein"),
    Quote("Help your pieces so they can help you.", "Paul Morphy"),
    Quote("Tactics flow from a superior position.", "Bobby Fischer"),
    Quote("Some sacrifices are sound; the rest are mine.", "Mikhail Tal"),
    Quote("Best by test: 1. e4.", "Bobby Fischer"),
    Quote("A bad plan is better than none at all.", "Frank Marshall"),
    Quote("Chess is everything: art, science and sport.", "Anatoly Karpov"),
    Quote("Not all artists are chess players, but all chess players are artists.", "Marcel Duchamp"),
    Quote("Chess is imagination.", "David Bronstein"),
    Quote("There just isn’t enough televised chess.", "David Letterman"),
  ],
};

export default settings;
