// React
import React, { Component } from 'react';

import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import PropTypes from 'prop-types';

// Custom
import settings from '../../settings';

import Bar from './Bar';

import Home from '../Home';
import Settings from '../Settings';
import BoardExplorer from '../BoardExplorer';
import VideoSearch from '../VideoSearch';
import VideoWatch from '../VideoWatch';
import DiagramLibrary from '../DiagramLibrary';

import CheckoutSuccessfulContent from '../Checkout/Successful';
import CheckoutCanceledContent from '../Checkout/Canceled';

import SettingsDialog from '../../Dialogs/Settings';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import SubscriptionPlansDialog from '../../Dialogs/SubscriptionPlans';
// import VideoTutorialsDialog from '../../Dialogs/VideoTutorials';

import ChromeExtensionAuth from '../ChromeExtension/Auth';
import ChromeExtensionUpgrade from '../ChromeExtension/Upgrade';

import { SubscriptionStorage } from '../../db';

import { eventTypes, emitEvent } from '../../events';


class App extends Component {
  constructor(props) {
    super(props);

    this.subscriptionStorage = new SubscriptionStorage(this.props.user.uid);

    this.state = {
      isPerformingAuthAction: false,

      settingsDialog: false,

      signOutDialog: false,

      subscriptionPlansDialog: false,
      subscriptionPlansDialogLoading: false,
      subscriptionPlansDialogDiscount: false,

      // videoTutorialsDialog: false,
    };
  }

  async getSubscription() {
    const snap = await this.subscriptionStorage.get();
    if (snap.exists()) {
      const sub = snap.data();
      const now = Math.floor(Date.now() / 1000);
      sub.ended_at = sub.end <= now ? sub.end : null;
      return sub;
    }
    return null;
  };

  cancelSubscription = () => {
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/subscriptions`;
      return fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          cancel: true,
        }),
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
        }),
      })
      .then(response => response.json());
    });
  }

  openSettingsDialog = () => {
    this.setState({
      settingsDialog: true
    });
  };

  closeSettingsDialog = (callback) => {
    this.setState({
      settingsDialog: false,
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  /*
  openVideoTutorialsDialog = () => {
    emitEvent(eventTypes.videoTutorialsDialogOpen);
    this.setState({
      videoTutorialsDialog: true,
    });
  };

  closeVideoTutorialsDialog = () => {
    this.setState({
      videoTutorialsDialog: false,
    });
  };
  */

  openSubscriptionPlansDialog = () => {
    const { user } = this.props;
    this.setState({
      subscriptionPlansDialogLoading: true,
    }, () => {
      return user.getIdToken().then(authToken => {
        const url = `${settings.apiUrl}/ebook_reader_subscriptions`;
        return fetch(url, {
          headers: new Headers({
           'Authorization': `Bearer ${authToken}`,
          }),
        })
        .then(response => response.json())
        .then(data => {
          this.setState({
            subscriptionPlansDialog: true,
            subscriptionPlansDialogLoading: false,
            subscriptionPlansDialogDiscount: data["active"],
          });
        });
      });
    });
  };

  closeSubscriptionPlansDialog = (callback) => {
    emitEvent(eventTypes.subscriptionPlansDialogCancel);

    this.setState({
      subscriptionPlansDialog: false,
      subscriptionPlansDialogLoading: false,
      subscriptionPlansDialogDiscount: false,
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openSignOutDialog = () => {
    this.setState({
      signOutDialog: true,
    });
  };

  closeSignOutDialog = (callback) => {
    this.setState({
      signOutDialog: false,
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  render() {
    const { user, signOut } = this.props;
    const { themeMode, onSetThemeMode } = this.props;

    const {
      isPerformingAuthAction,
      settingsDialog,
      subscriptionPlansDialog,
      subscriptionPlansDialogLoading,
      subscriptionPlansDialogDiscount,
      signOutDialog,
      // videoTutorialsDialog,
    } = this.state;

    return (
      <>
        <Bar
          user={user}
          getSubscription={async () => await this.getSubscription() }
          onSettingsClick={() => this.openSettingsDialog()}
          onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
          onUpgradeLoading={subscriptionPlansDialogLoading}
          onSignOutClick={this.openSignOutDialog}
          themeMode={themeMode}
          onSetThemeMode={onSetThemeMode}
        />
        <Switch>
          <Route path={settings.routes.HOME} exact>
            <Home />
          </Route>
          <Route path={settings.routes.SETTINGS}>
            <Settings 
              user={user}
              isPerformingAuthAction={isPerformingAuthAction}
              onCancelSubscriptionClick={this.cancelSubscription}
              onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
              getSubscription={async () => await this.getSubscription() }
              onUpgradeLoading={subscriptionPlansDialogLoading}
              onExtendClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
            />
          </Route>
          <Route path={`${settings.routes.BOARD_EXPLORER}/:fen*`} exact>
            <BoardExplorer
              user={user}
              onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
              onUpgradeLoading={subscriptionPlansDialogLoading}
            />
          </Route>
          <Route path={settings.routes.VIDEO_SEARCH} exact>
            <VideoSearch
              user={user}
              onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
              onUpgradeLoading={subscriptionPlansDialogLoading}
            />
          </Route>
          <Route path={`${settings.routes.VIDEO_WATCH}/:videoId?`} exact>
            <VideoWatch
              user={user}
              onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
              onUpgradeLoading={subscriptionPlansDialogLoading}
            />
          </Route>
          <Route path={settings.routes.DIAGRAM_LIBRARY} exact>
            <DiagramLibrary user={user} />
          </Route>
          <Route path={settings.routes.CHECKOUT_SUBSCRIPTION_SUCCESSFUL} exact render={() => {
            const { title, text } = settings.messages.checkout.subscription.successful;
            return <CheckoutSuccessfulContent title={title} text={text} />;
            }}
          />
          <Route path={settings.routes.CHECKOUT_SUBSCRIPTION_CANCELED} exact render={() => {
            const { title, text } = settings.messages.checkout.subscription.canceled;
            return <CheckoutCanceledContent title={title} text={text} />;
            }}
          />
          <Route path={settings.routes.CHROME_EXTENSION_AUTH} exact>
            <ChromeExtensionAuth user={user} />
          </Route>
          <Route path={settings.routes.CHROME_EXTENSION_UPGRADE} exact>
            <ChromeExtensionUpgrade user={user} />
          </Route>
          <Route path={settings.routes.CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_SUCCESSFUL} exact render={() => {
            const { title, text } = settings.messages.chromeExtensionCheckout.subscription.successful;
            return <CheckoutSuccessfulContent title={title} text={text} />;
            }}
          />
          <Route path={settings.routes.CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_CANCELED} exact render={() => {
            const { title, text } = settings.messages.chromeExtensionCheckout.subscription.canceled;
            return <CheckoutCanceledContent title={title} text={text} />;
            }}
          />
          <Redirect to={settings.routes.HOME} />
        </Switch>

        <SubscriptionPlansDialog
          open={subscriptionPlansDialog}
          title="Select Your Plan"
          cancelText="Cancel"
          user={user}
          onClose={this.closeSubscriptionPlansDialog}
          onCancelClick={this.closeSubscriptionPlansDialog}
          discount={subscriptionPlansDialogDiscount}
        />

        <SettingsDialog
          open={settingsDialog}

          user={user}
          isPerformingAuthAction={isPerformingAuthAction}

          onClose={this.closeSettingsDialog}
          onCancelSubscriptionClick={this.cancelSubscription}
          getSubscription={async () => await this.getSubscription() }
          onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
          onUpgradeLoading={subscriptionPlansDialogLoading}
          onExtendClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
        />

        <ConfirmationDialog
          open={signOutDialog}

          title="Do you want to logout?"
          content=""
          okText="Logout"
          disableOkButton={isPerformingAuthAction}
          highlightOkButton

          onClose={this.closeSignOutDialog}
          onCancelClick={this.closeSignOutDialog}
          onOkClick={signOut}
        />
      </>
    );
  }
}

App.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withRouter(App);
